<template>
  <div class="learner_mine_card" @click="cardClick">
    <div class="row">
      <div class="img_wrap"></div>
      <div class="col" v-if="info.isJoined">
        <div class="title">{{ info.groupName }}</div>
        <div class="mentor">
          <div
            v-if="info.teacherDTOList && info.teacherDTOList.length && info.teacherDTOList[0].avatar"
            class="avatar_wrap"
            :style="`background-image: url(${info.teacherDTOList[0].avatar})`"
          ></div>
          <div
            v-else
            class="avatar_wrap"
          ></div>

          <p class="name">
              {{ info.teacherDTOList && info.teacherDTOList.length >= 2 ? 
                `${info.teacherDTOList[0].teacherName}...等${info.teacherDTOList.length}位导师` :
                info.teacherDTOList && info.teacherDTOList.length === 1 ?
                info.teacherDTOList[0].teacherName :
                "暂未分配导师" }}
            </p>
        </div>
        <p>已完成{{ info.classedNum }}/{{ info.classTotal }}</p>
      </div>
      <div class="col" v-else>
        <div class="title">{{ info.groupName }}</div>
        <div class="btn_wrap">
          <Button type="plain" @click="routerChange('learner-class')">
            选班级
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../../components/Button.vue";

export default {
  props: ["info"],
  methods: {
    routerChange(name) {
      this.$router.push({
        name,
        query: {
          classTotal: this.info.classTotal,
          courseId: this.info.courseId,
        },
      });
    },
    cardClick() {
      if (this.info.isJoined) {
        const { id } = this.info;
        this.$router.push({
          path: `/learner/history/detail`,
          query: { id },
        });
      }
    },
  },
  components: { Button },
};
</script>
<style lang="stylus" scoped>
@import "../../../theme.styl"
@import "../../../common.styl"
.learner_mine_card
  border-bottom 1px solid #F3F3F3
  padding 15px 0
  height: 110px;
  .img_wrap
    width: 110px;
    height: 100%;
    border-radius 4px
    bgFill()
    margin-right 12px
    flex-shrink 0
    background-image url('../../../assets/images/sibaiji.jpg')
  .title
    font-size: fz16px;
    color: #444F6A;
    line-height: fz20px;
    width 223px
    vendor(1)
    padding-left 6px
  .row
    height 100%
    justify-content flex-start
    .mentor
      font-size fz14px
      color: #797C80;
      display flex
      justify-content flex-start
      width 100%
      .avatar_wrap
        width: 16px;
        height: 16px;
        border-radius 50%
        margin-right 6px
        bgFill()
        background-image: url('./images/avator.jpg')
      .name
        vendor(1)
        color: #797C80
        font-size 14px
    p
      width 100%
      color: #797C80;
      font-size 12px
  .col
    flex-direction column
    justify-content space-between
    height 100%
    width 223px
  .btn_wrap
    display flex
    justify-content flex-end
    width 100%
</style>
