<!--  学生 - 我的 -->
<template>
  <div class="learner_mine">
    <PersonalInfo :info="{ avatar, nickname, role }" />
    <div class="row line" @click="routerChange('learner-timeSeting')">
      <div class="col">
        <div class="icon icon1"></div>
        <span>空闲时间</span>
      </div>
      <div class="col">
        <div class="arrow"></div>
      </div>
    </div>
    <div class="list" v-if="list.length">
      <Card v-for="item in list" :key="item.id" :info="item" />
    </div>
    <Empty :tips="'暂未加入班级'" v-show="!list.length" />
  </div>
</template>
<script>
import PersonalInfo from "../../components/PersonalInfo.vue";
import Card from "./Card.vue";
import { getGroupAll } from "../../../api/learner";
import Empty from "../../components/Empty.vue";
import { getUserInfo } from "@/api/common";

export default {
  name: "learner-mine",
  data() {
    return {
      role: "learner",
      avatar: localStorage.getItem("avatar"),
      nickname: localStorage.getItem("name") || "学生名字",
      endList: [],
      onList: [],
    };
  },
  computed: {
    list() {
      return Array.prototype.concat.call(this.onList, this.endList);
    },
  },
  methods: {
    routerChange(name) {
      this.$router.push({
        name,
      });
    },
    async initData(index) {
      const isEnd = index === 1;
      const data = await getGroupAll({ isEnd });
      if (isEnd) {
        this.endList = data;
      } else {
        this.onList = data;
      }
    },
  },
  async created() {
    let res = await getUserInfo();
    if (res && res.data) {
      this.avatar = res.data.avatar;
      localStorage.setItem("avatar", res.data.avatar);

      this.nickname = res.data.name;
      localStorage.setItem("name", res.data.name);
    }
    this.initData(0);
    this.initData(1);
    document.title = "我的";
  },
  components: {
    PersonalInfo,
    Card,
    Empty,
  },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.learner_mine {
  background: #fff;
  height: 100vh;

  .list {
    padding: 0 15px 100px;
  }
}

.row {
  padding: 16px 15px 16px 14px;

  // padding: 0 30px 0 15px;
  // margin: 0 auto;
  // width: 345px;
  // height: 76px;
  // background: #FFFFFF;
  // border-radius: 8px;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    bgFill();
  }

  .icon1 {
    background-image: url('./images/clock2.png');
  }

  .icon2 {
    background-image: url('./images/我的课程.png');
  }

  span {
    font-size: fz16px;
    color: #10213F;
    letter-spacing: 1px;
  }

  .status {
    font-size: fz12px;
    color: #717479;
    margin-right: 6px;
  }

  .arrow {
    background-image: url('../../../assets/images/arrow.png');
  }
}
</style>
