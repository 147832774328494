<template>
  <div class="personal_info">
    <div class="avatar_box">
      <div class="avatar">
        <img v-if="info.avatar" :src="info.avatar" />
        <img v-else src="./images/avator.jpg" />
      </div>
      <!-- <div class="level" :class="info.role"></div> -->
    </div>
    <div class="nickname">{{ info.nickname }}</div>
    <div class="seting" @click="handleSet">设置</div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    handleSet() {
      let href = window.location.href;
      if (href.includes("mentor")) {
        this.$router.push("/mentor/seting");
      } else {
        this.$router.push("/learner/seting");
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../../common.styl';
@import '../../theme.styl';

.personal_info {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 0.5px solid #f3f3f3;

  .avatar_box {
    width: 80px;
    height: 80px;
    position: relative;

    .avatar {
      width: 100%;
      height: 100%;
      bgFill();
      border-radius: 50%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .avatar img {
      width: 100%;
      height: 100%;
    }

    .level {
      width: 19px;
      height: 16px;
      bgFill();
      position: absolute;
      top: 1px;
      right: -5px;

      &.learner {
        background-image: url('./images/learner_vip.png');
      }

      &.mentor {
        background-color: #999;
      }
    }
  }

  .nickname {
    font-size: fz18px;
    color: #1A1A1A;
    line-height: 1;
    padding-left: 16px;
    width: 200px;
    vendor(1);
  }

  .seting {
    width: 40px;
    height: 20px;
    line-height: 20px;
    font-size: fz14px;
    text-align: right;
  }
}
</style>