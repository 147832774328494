<template>
  <div class="empty">
    <div class="icon"></div>
    <p class="tips">{{ tips || "暂无课时记录，请等待班主任排课" }}</p>
  </div>
</template>
<script>
export default {
  props: ["tips"],
};
</script>
<style lang="stylus" scoped>
@import '../../common.styl';
@import '../../theme.styl';

.empty {
  padding-top: 123px;

  .icon {
    width: 132px;
    height: 116px;
    margin: 0 auto;
    bgFill();
    background-image: url('../../assets/images/empty.png');
  }

  .tips {
    padding-top: 32px;
    text-align: center;
    font-size: fz16px;
    color: #C6C6C6;
    line-height: 1;
  }
}
</style>